/* 必須ラベル */
label {
  display: block;
}

label.required:after {
  margin-left: 1.0em;
  padding: 0px 6px 1px 6px;
  border-radius: 4px;
  font-size: 0.6em;
  color: white;
  background-color: #C44;
  content: "必須";
}

/* パスワードEyeの位置 */
.PasswordReveal {
  left: auto;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 3;
}

/* React-datepicker のカスタムCSS
   bootstrapの.form-controlと同じデザインになるように調整
*/
.react-datepicker__input-container {
  padding: 0;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  /*  background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-datepicker-wrapper {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* 松本デザイン */

/* コントロールの基本色 */
.form-control {
  background-color: #F2F3F7;
  border: #F2F3F7;
}

/* プレースフォルダ指定時のテキストの文字色 */
.form-control::placeholder {
  color: #CBCBCC;
}

/* ボタン */
.btn-primary,
.btn-primary:hover {
  background-color: #798092;
  border: #798092;
  color: #ffffff;
}

.btn-success,
.btn-success:hover {
  background-color: #798092;
  border: #798092;
  color: #ffffff;
}

.btn-success:disabled {
  color: #fff;
  background-color: #798092;
  border-color: #798092;
}

.btn-no,
.btn-no:hover {
  background-color: #DDDDDD;
  border: #DDDDDD;
  color: #000;
}

/* エラー表示用の枠 */
.error-box {
  padding: 0.5em 1em;
  margin: 2em 0;
  font-weight: bold;
  color: #ff3366;
  /*文字色*/
  background: #FFF;
  border: solid 3px #ff3366;
  /*線*/
  border-radius: 10px;
  /*角の丸み*/
}

.error-box p {
  margin: 0;
  padding: 0;
}

/* SalonLoginDialog.tsx */
.login-title {
  font-size: xx-large;
  font-family: serif;
  color: #858B9C;
}

input#salonID {
  height: 70px;
  font-size: larger;
  padding-left: 20px;
}

input#userID {
  height: 70px;
  font-size: larger;
  padding-left: 20px;
}

input#password {
  height: 70px;
  font-size: larger;
  padding-left: 20px;
}

/* ログインボタン */
.login-button {
  height: 75px;
  width: 250px;
  font-size: larger;
}

/* ログインボタン下のメッセージ */
.login-message {
  font-weight: bold;
  font-size: smaller;
  color: #353535;
}

/* Header.tsx */
.header-custom {
  background: linear-gradient(to right, #7B8094, #FDFDFD);
  color: #FFF;
  font-family: serif;
}

/* Footer.tsx */
.footer-custom {
  color: #DADADA;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: smaller;
  background-color: #fff;
}

/* Navber */
/* Navbarの右側のMenuボタン */
.navbar-toggler {
  /*枠線*/
  border-color: transparent;
  /*クリック時の枠線*/
  color: transparent;
  /*背景色*/
  background-color: transparent;
}

.navbar-toggler-icon {
  /* 三本線 */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(144, 150, 168, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* ヘッダーバーのメニューの文字色と枠色 iPadに合わせる */
.btn-outline-light {
  color: #000;
  border-color: #000;
  background-color: #F2F3F5;
}

/*
 MainMenu.tsx 
*/
/* ようこそ */
.menu-welcome {
  font-size: larger;
  font-weight: bold;
}

/* 契約店舗（タイトル） */
.menu-store-title {
  font-size: medium;
  font-weight: bold;
  color: #878C9C;
  letter-spacing: 1px;
}

/* 契約店舗 */
.menu-store-name {
  font-size: x-large;
  font-weight: bold;
  letter-spacing: 1px;
}

/* メニュータイトル（未使用） */
.menu-title {
  font-size: medium;
  font-weight: bold;
  color: #878C9C;
  letter-spacing: 1px;
}

/* お知らせ */
.menu-message-memberId {
  font-size: smaller;
  color: #878C9C;
  font-weight: bold;
}

.menu-message {
  font-weight: bold;
}

.menu-message-none {
  background-color: #F2F3F5;
  border-color: #F2F3F5;
  font-weight: bold;
  padding-top: 13px;
  padding-bottom: 13px;
}

/* リストグループ */
.list-group-item {
  /*上下中央*/
  display: flex;
  align-items: center;
  /*高さ*/
  height: 50px;
  background-color: #F2F3F5;
  border-color: #F2F3F5;
  font-weight: bold;
  color: #000;
}

.list-group-header {
  /*上下中央*/
  display: flex;
  align-items: center;
  /*文字色*/
  color: #7D8296;
}

.list-group-title,
.item-title {
  /*文字色*/
  color: #7D8296;
}

.list-group-data {
  /*文字色*/
  color: #000;
}

.item-title-light {
  /*文字色*/
  color: #7D8296;
  font-weight: bold;
  font-size: small;
}



/* アコーディオン */
.accordion-body {
  padding: 0px;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  color: rgb(204, 68, 68);
  background-color: rgb(250, 217, 222);
  border-color: rgb(250, 217, 222);
  font-weight: bold;
}

.accordion-button::after {
  /* アコーディオンの右の上向き矢印アイコンの色を文字色#cc4444にする */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cc4444'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.accordion-button:not(.collapsed)::after {
  /* アコーディオンの右の下向き矢印アイコンの色を文字色#cc4444にする */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23cc4444'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

/* ページタイトル */
.page-title-row {
  padding-right: 0;
  color: #878C9C;
}

.page-title {
  padding-top: 7px;
}

.icon-gray {
  /* アイコン色 */
  color: #7D8296;
}

/* アテンション表示用の枠 */
.attention-box {
  padding: 1.5em 1em;
  margin: 2em 0;
  font-size: smaller;
  font-weight: bold;
  color: #ff3366;
  /*文字色*/
  border: solid 1px #ff3366;
  /*線*/
  text-align: center;
}

.attention-box p {
  margin: 0;
  padding: 0;
}

/* ログインボタン */
.btn-success,
.btn-no {
  height: 75px;
  width: 250px;
  font-size: larger;
}

.btn-agree-success {
  height: 75px;
  width: 270px;
  font-size: medium;
  background-color: forestgreen !important;
  color: #fff !important;
}

.btn-agree-danger {
  height: 75px;
  width: 270px;
  font-size: medium;
  border: 3px solid #ff3366 !important;
  color: #ff3366 !important;
}

.btn-dialog-ok,
.btn-dialog-ok:hover {
  width: 150px;
  background-color: #798092;
  border: #798092;
  color: #ffffff;
}

.btn-dialog-cancel,
.btn-dialog-cancel:hover {
  width: 150px;
  background-color: #DDDDDD;
  border: #DDDDDD;
  color: #000;
}

/*利用規約の内容に同意しますボタン*/
.btn-agree {
  /*上下中央*/
  align-items: center;
  padding-top: 24px;
  /*幅・高*/
  /*width: 350px;*/
  height: 75px;
}

/* エラーメッセージ文字色 */
.text-danger {
  color: #ff3366 !important;
}

/* 銀行・郵便局のトグル */
.btn-outline-primary {
  color: #798092;
  border-color: #798092;
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #798092;
  border-color: #798092;
}



.card-checked {
  background-color: #C3E6CB;
  color: #15576F;
}

.card-unchecked {
  background-color: #F5C6CB;
  color: #871C24;
}


.paycalllist-list-group {
  height: 35px;
}

.paycalllist-no-list-group {
  height: 140px;
}

.furikae-ok {
  padding: 2px 14px;
  font-size: smaller;
  color: #00CC00;
  border-radius: 100vh;
  border: 0.5px solid #00CC00;
  background-color: #fff;
}

.furikae-ng,
.card-ng {
  padding: 2px 14px;
  font-size: smaller;
  color: #ff3366 !important;
  border-radius: 100vh;
  border: 0.5px solid #ff3366 !important;
  background-color: #fff;
}

.furikae-select {
  height: 40px;
}

.furikae-select-off {
  white-space: nowrap;
  padding: 2px;
  margin-left: 10px;
  font-size: medium;
  color: #798092;
  border-radius: 100vh;
  border: 0.5px solid #798092;
  background-color: #fff;
}

.furikae-select-on {
  white-space: nowrap;
  padding: 2px;
  margin-left: 10px;
  font-size: medium;
  color: #fff;
  border-radius: 100vh;
  border: 0.5px solid #798092;
  background-color: #798092;
}

.history-transferAmount {
  text-align: right;
  padding-right: 10px;
}

.history-more {
  padding: 1.5em 1em;
  margin: 2em 0;
  font-size: smaller;
  font-weight: bold;
  color: #000;
  /*文字色*/
  border: solid 1px #798092;
  /*線*/
  /*border-radius: 100vh;*/
  text-align: center;
}